<template>
    <div class="relative text-left" ref="bubble" v-if="(path && !pending && data?.data) || isAdmin">
        <ElementsIcon icon="help" size="big"
            class="p-2 transition-all duration-200 cursor-pointer hover:bg-gray-200 dark:hover:bg-slate-800 rounded-full"
            v-bind:class="{
                'bg-gray-200 dark:bg-slate-800': showHelpBubble,
            }"
            @click.stop.prevent="showHelpBubble =  !showHelpBubble" />
        <TransitionsGrow>
            <div v-if="showHelpBubble" ref="optionsMenu" class="rounded-2xl drop-shadow-lg border transition-all
                absolute bg-white dark:bg-black text-black dark:text-white dark:border-black z-20 min-w-[18rem]"
                v-bind:class="{
                    '-right-1.5': alignRight,
                    '-left-1.5': !alignRight,
                    'top-full -mt-0.5': alignBottom,
                    'bottom-full -mb-0.5': !alignBottom,
                }">
                <div class="py-2 px-4 flex gap-3 items-start whitespace-pre-wrap break-words z-10">
                    <ElementsIcon icon="messageBubble" class="shrink-0 mt-1.5" />
                    <div>
                        <NuxtLink v-if="isAdmin" :to="{ name: 'configuration-help-text-slug', params: { slug: path } }" class="block mb-2">
                            <p>Hilfe-Text: {{path}}</p>
                            <p class="flex items-center">Jetzt bearbeiten <ElementsIcon icon="carretLeft" /></p>
                        </NuxtLink>
                        <p class="text-xl">{{ data?.data?.title || data?.data?.attributes?.title }}</p>
                        <p class="text-base text-gray-800 dark:text-gray-300">{{ data?.data?.text || data?.data?.attributes?.text }}</p>
                    </div>
                </div>
                <div class="absolute w-2.5 h-2.5 bg-white dark:bg-black rotate-45 mt-px -z-10"
                    v-bind:class="{
                        'right-4': alignRight,
                        'left-4': !alignRight,
                        '-top-1.5': alignBottom,
                        '-bottom-1.5': !alignBottom,
                    }"></div>
            </div>
        </TransitionsGrow>
    </div>
</template>
<script setup>
import { onClickOutside, useElementSize, useElementBounding, watchPausable } from '@vueuse/core'
const props = defineProps({
    path: { type: String, default: null },
})
const emit = defineEmits(['helper'])
const isAdmin = useIsAdmin()
if(isAdmin) emit('helper')

const bubble = ref(null)
const showHelpBubble = ref(false)
onClickOutside(bubble, (event) => showHelpBubble.value = false)

const { findOne } = useStrapi4()


const alignBottom = ref(true)
const alignRight = ref(true)
const optionsMenu = ref(null)
const { width, height } = useElementSize(optionsMenu)
const { x, y } = useElementBounding(bubble)
watch(width, (w) => { alignRight.value = x.value - w > 10 })
watch(height, (h) => { alignBottom.value = y.value + h < window.innerHeight })

const { data, pending } = useLazyAsyncData(
    'help-'+props.path,
    () => findOne('help-texts', props.path)
)
const { stop } = watchPausable(data, (n) => {
    if(n?.data)
        emit('helper')
        stop()
})
if(data?.value?.data) emit('helper')
</script>